*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
}
